// src/components/Navbar.js
import React, { useState } from 'react';
import { Navbar, Nav, Button, Offcanvas, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';  // Optional, if you use React Router
import './Navbar.css';

// Importing the logo image from the assets folder
import Logo from '../assets/logo.jpg';  // Adjust path as per your file structure

function NavbarComponent() {
  const [show, setShow] = useState(false);  // Manage the offcanvas state

  // Toggle the Offcanvas visibility
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* Main Navbar */}
      <Navbar bg="dark" variant="dark" expand="lg" className="navbar">
        <Container>
          {/* Logo Image inside Navbar */}
          <Navbar.Brand href="#home">
            <img
              src={Logo}  // Use the imported image here
              alt="True Earn Logo"
              width="150"   // Adjust width to fit your design
              height="auto"
              className="logo"
            />
          </Navbar.Brand>

          {/* Hamburger Icon (Toggle for Mobile) */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleShow} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto navbar-links">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link href="#services">Services</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Nav>
            {/* Sign Up Button - Link to Google Form */}
            <a href="https://forms.gle/ehMKgpgk15bkX7R87" target="_blank" rel="noopener noreferrer">
              <Button variant="primary" className="ms-2 sign-up-btn">Sign Up</Button>
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Offcanvas Navigation for Mobile */}
      <Offcanvas show={show} onHide={handleClose} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>TrueEarn</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link href="#home" onClick={handleClose}>Home</Nav.Link>
            <Nav.Link href="#about" onClick={handleClose}>About</Nav.Link>
            <Nav.Link href="#services" onClick={handleClose}>Services</Nav.Link>
            <Nav.Link href="#contact" onClick={handleClose}>Contact</Nav.Link>
            {/* Sign Up Button */}
            <a href="https://forms.gle/ehMKgpgk15bkX7R87" target="_blank" rel="noopener noreferrer">
              <Button variant="primary" className="w-100" onClick={handleClose}>Sign Up</Button>
            </a>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default NavbarComponent;
