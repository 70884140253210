// src/components/Services.js
import React from 'react';
import './Services.css';
import { Container, Row, Col, Card } from 'react-bootstrap';

// Importing images from the assets folder
import BpoImage from '../assets/bpo.jpg';
import TelecomImage from '../assets/telecom.jpg';
import SalesMarketingImage from '../assets/sales-and-marketing.webp';
import EcommerceImage from '../assets/ecommerce.jpg';
import FreelanceImage from '../assets/feelnacer.jpg';
import FinancialConsultingImage from '../assets/financial.jpg';

function Services() {
  return (
    <section id="services" className="services bg-light py-5">
      <Container>
        <h2 className="text-center mb-4">Our Services</h2>
        <Row>
          {/* BPO Services */}
          <Col md={4}>
            <Card>
              <Card.Img variant="top" src={BpoImage} />
              <Card.Body>
                <Card.Title>BPO Services</Card.Title>
                <Card.Text>Outsource your operations for efficiency and focus on growth.</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Telecom Solutions */}
          <Col md={4}>
            <Card>
              <Card.Img variant="top" src={TelecomImage} />
              <Card.Body>
                <Card.Title>Telecom Solutions</Card.Title>
                <Card.Text>Optimize communication systems with scalable telecom services.</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Sales & Marketing */}
          <Col md={4}>
            <Card>
              <Card.Img variant="top" src={SalesMarketingImage} />
              <Card.Body>
                <Card.Title>Sales & Marketing</Card.Title>
                <Card.Text>Boost your brand with digital marketing and sales strategies.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          {/* E-Commerce Solutions */}
          <Col md={4}>
            <Card>
              <Card.Img variant="top" src={EcommerceImage} />
              <Card.Body>
                <Card.Title>E-Commerce Solutions</Card.Title>
                <Card.Text>Build and grow your online store with our e-commerce services.</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Freelance & Remote Work */}
          <Col md={4}>
            <Card>
              <Card.Img variant="top" src={FreelanceImage} />
              <Card.Body>
                <Card.Title>Freelance & Remote Work</Card.Title>
                <Card.Text>Connect with clients for remote work and freelance opportunities.</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Financial Consulting */}
          <Col md={4}>
            <Card>
              <Card.Img variant="top" src={FinancialConsultingImage} />
              <Card.Body>
                <Card.Title>Financial Consulting</Card.Title>
                <Card.Text>Get expert advice to manage your finances and grow your wealth.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Services;
