// src/components/Hero.js
import React, { useEffect } from 'react';
import './Hero.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import AOS styles

// Import local images
import heroImage from '../assets/image.webp'; // Local image for hero section
import googlePlayLogo from '../assets/googlePlayButton.08085542.svg'; // Local Google Play logo

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });  // Initialize AOS for animations
  }, []);

  return (
    <section id="home" className="hero py-5">
      <Container>
        <Row className="align-items-center">
          {/* Left Column: Title, Description, Button */}
          <Col md={6} data-aos="fade-up">
            <h1>Join TrueEarn and Earn Rs.1 Lakh/Month</h1>
            <p>No upfront investment required. Work from anywhere, set your own schedule, and start earning today.</p>

            {/* Get Started and Google Play Store Button (side by side) */}
            <div className="btn-container">
              <Button variant="primary" size="lg" href="https://forms.gle/ehMKgpgk15bkX7R87" className="get-started-btn">
                Get Started
              </Button>

              <a href="https://play.google.com" target="_blank" rel="noopener noreferrer" className="google-play-container">
                <img
                  alt="google-play-logo"
                  loading="lazy"
                  width="208"
                  height="63"
                  src={googlePlayLogo}  // Use local Google Play logo
                  className="google-play-btn"
                />
              </a>
            </div>
          </Col>

          {/* Right Column: Hero Image */}
          <Col md={6} data-aos="fade-up" data-aos-delay="200">
            <img
              src={heroImage}  // Use local hero image
              alt="Hero Image"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
