import React from 'react';
import './CompetitiveBanner.css';

const CompetitiveBanner = () => {
  return (
    <section className="competitive-banner">
      <div className="container">
        <div className="row">
          {/* Left Content */}
          <div className="col-lg-8 col-md-7 col-sm-12 left-content">
            <p className="competitve-banner-heading">
              Looking for the best way to earn effortlessly?
            </p>
            <p className="competitive-banner-content">
              TrueEarn offers you a hassle-free way to build your income by partnering with top-tier financial products. Let us guide you towards a limitless earning potential.
            </p>

            <div className="count-background">
              <div className="ui-components-count">
                <img
                  width="12"
                  height="10"
                  className="green-tick"
                  src="https://cdn.syncfusion.com/content/images/FT/common/green-tick-mark.svg"
                  alt="tick-mark"
                  loading="lazy"
                />
                <span>100+ Trusted Partners</span>
              </div>

              <div className="demos-count">
                <img
                  width="12"
                  height="10"
                  className="green-tick"
                  src="https://cdn.syncfusion.com/content/images/FT/common/green-tick-mark.svg"
                  alt="tick-mark"
                  loading="lazy"
                />
                <span>500+ Active Users</span>
              </div>

              <div className="downloads-count">
                <img
                  width="12"
                  height="10"
                  className="green-tick"
                  src="https://cdn.syncfusion.com/content/images/FT/common/green-tick-mark.svg"
                  alt="tick-mark"
                  loading="lazy"
                />
                <span>5M+ Successful Transactions</span>
              </div>
            </div>

            <div className="explore-FT-comparison">
              <a
                href="/pages/partner-with-us/"
                target="_blank"
                className="ft-competitive-link"
                id="TrueEarn-Comparison"
              >
                Explore TrueEarn Partnership
              </a>
            </div>
          </div>

          {/* Right Content (Image) */}
          <div className="col-lg-4 col-md-5 col-sm-12 competitive-banner-FT-image">
            <object
              width="100%"
              height="100%"
              className="ft-icon"
              type="image/svg+xml"
              data="https://cdn.syncfusion.com/content/images/platform-ft/react-competitive-animated-image.svg?v=25102024114056"
              aria-label="TrueEarn Image"
            ></object>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompetitiveBanner;
 