import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import NavbarComponent from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/Services';

import BrandLogos from './components/BrandLogos';


import CompetitiveBanner from './components/CompetitiveBanner';
import ContactUs from './components/ContactUs';


import Footer from './components/Footer';






function App() {
  return (
    <div className="App">
      <NavbarComponent />
      <Hero />
      <About />
      <CompetitiveBanner/>

      <BrandLogos/>
     
      
      
      
  
      
      
      <Services />
      <ContactUs/>
      <Footer/>
      
      
      
    </div>
  );
}

export default App;
