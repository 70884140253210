// src/components/BrandLogos.js
import React from 'react';
import { motion } from 'framer-motion';
import './BrandLogos.css';

// Importing local images
import logo1 from '../assets/Amazon.png';
import logo2 from '../assets/hdfc.webp';
import logo3 from '../assets/ebay.png';
import logo4 from '../assets/flipkart.png';
import logo5 from '../assets/axisbank.png';
import logo6 from '../assets/meesho.png';

const logos = [logo1, logo2, logo3, logo4, logo5, logo6];

const BrandLogos = () => {
  return (
    <section className="brand-logos-section">
      <div className="container">
        <h2 className="section-heading">Top Brands on TRUE Partner</h2>
        <p className="section-subheading">
          We are trusted by the leading brands in the industry
        </p>
        <motion.div
          className="logos-container"
          animate={{ x: ['0%', '-100%'] }}
          transition={{
            duration: 15,  // Animation duration
            repeat: Infinity,  // Repeat indefinitely
            ease: 'linear',  // Smooth linear scrolling
          }}
        >
          {logos.map((logo, index) => (
            <div key={index} className="logo-item">
              <img src={logo} alt={`Brand ${index + 1}`} />
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default BrandLogos;
