import React from 'react';
import './About.css';
import { Container, Row, Col } from 'react-bootstrap';
import aboutUsImage from '../assets/about.png';  // Import the image from src/assets

function About() {
  return (
    <section id="about" className="about bg-dark text-white py-5">
      <Container>
        {/* About Text Section */}
        <Row>
          <Col md={6}>
            <h2>About Us</h2>
            <p>
              We are TrueEarn, a company dedicated to helping you achieve financial success. 
              Our mission is to provide top-notch services tailored to your financial goals, 
              whether you're looking to invest, save, or secure your future. With years of expertise 
              and a customer-first approach, we are here to guide you every step of the way.
            </p>
            <p>
              At TrueEarn, we focus on building long-term relationships with our clients, offering 
              personalized financial solutions that grow with you. Whether you're an individual or a business, 
              we have the tools and resources to help you succeed.
            </p>
          </Col>
          <Col md={6}>
            <img 
              src={aboutUsImage}  // Use the imported image
              alt="About Us Image" 
              className="img-fluid" 
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default About;
